@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Helvetica:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap');
/* Add Tajawal font */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: font-family 0.3s ease;
  -ms-transition: font-family 0.3s ease;
  transition: font-family 0.3s ease;
}

/* Arabic-specific font style */
body.lang-ar {
  font-family: 'Tajawal', sans-serif;
}

body.lang-en {
  font-family: 'Inter', 'Helvetica', sans-serif;
}

.reviewsParent {
  direction: ltr;
}

.bt-hover:hover {
  background-color: #ED1D24 !important;
  color: white !important;
  transition: all 0.3s ease-in-out;
}

.card-hover:hover {
  background-color: #008847 !important;
  color: white !important;
  scale: 1.1;
  transition: all 0.5s ease-in-out;
}

.white:hover {
  color: white;
}

.custom-order {
  order: 1;
}

@media (min-width: 768px) {
  .custom-order {
    order: unset;
  }
}

.changeButton:hover .tooltip-text {
  display: block;
}
